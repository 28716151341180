.app {
  display: flex;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 15px;
  min-height: 100vh;
  border-top: 10px;
  flex-direction: column;
  background-color: #ffffff;
  text-align: center;
}

@media (min-width: 800px) {
  .app {
    padding: 0 100px;
    margin-top: 5vh;
    min-height: 90vh;
  }
}
