.skills {
  flex-grow: 1;
}

.skilss h1 {
  margin-bottom: 20px;
}

.content {
  display: grid;
  font-size: 24px;
  grid-template-columns: 1fr 1fr;
}

.content div {
  margin-bottom: 25px;
}

.content strong {
  line-height: 25px;
  padding-bottom: 15px;
  display: block;
}

.content li {
  margin: 0;
  margin-bottom: 10px;
}

@media (min-width: 800px) {
  .content {
    grid-template-columns: 1fr 1fr 1fr;
  }
}